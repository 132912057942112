import React from 'react'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import { graphql } from 'gatsby';
import Seo from '../components/seo';

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const TeamLanding = loadable(() => import("../components/TeamLanding/TeamLanding"));
const CtaModule = loadable(() => import("../components/CtaModule/CtaModule"));

const TeamLandingTemplate = ({ data }, props) => {

    const PageData = data?.strapiPage
    const allTeams = data?.allStrapiTeam?.edges
    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule
                parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title}
                parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug}
                subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
                subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
                title={PageData.title}
            />

            <div className='layout-grey-bg-theme'>
                {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                    return (
                        <>
                            {module.strapi_component === "page-modules.plain-content" && <StaticIntro {...module} pagename={PageData.title} />}
                        </>
                    )
                })}

                <TeamLanding allTeam={allTeams} />

                <CtaModule />
            </div>
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}


export default TeamLandingTemplate

export const query = graphql`
    query ($page_id: String) {
        strapiPage(id: {eq: $page_id}) {
            ...PageFragment
            Add_Page_Modules {
                ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                    ...PlainContentFragment
                }
            }
        }
        allStrapiTeam(filter: {publish: {eq: true}}) {
            edges {
                node {
                    ...TeamFragment
                    slug
                    offices {
                        slug
                        title
                    }
                    category {
                        strapi_json_value
                    }
                }
            }
        }
    }
`